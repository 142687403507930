import React from "react";
import { withOAuth } from "aws-amplify-react";
import googleIcon from "../assets/google_web_light_rd_na.svg"

function OAuthButton(props) {
  return (
    <button
      className="button has-text-weight-bold"
      onClick={props.OAuthSignIn}>
      <span className="icon">
        <img
        className="image is-32x32"
        src={googleIcon}
        alt="Google icon"/>
      </span>
      <span>Connexion</span>
    </button>
  )
}

export default withOAuth(OAuthButton);
